.bsd-create-email {
  &-email-input {
    margin: 20px 0 30px 0;

    input {
      @include responsive($width-medium) {
        width: 328px;
      }
    }
  }

  &-captcha {
    padding-top: 10px;

    &.is-invalid {
      .bsd-form-field-input-wrapper::after {
        content: none;
      }
  
      iframe {
        border: 2px solid $red;
      }
    }
  }
}
